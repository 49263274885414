<template>
  <div class="media-card">
    <div
      class="card bg-navy bg-img-hero h-100 transition-3d-hover mx-2"
      :style="{ backgroundImage: `url(${require(`@/assets/svg/components/${background}.svg`)})`}"
    >
      <a :href="link" target="_blank">
        <div class="card-body">
          <h3 class="text-white">{{ title }}</h3>
        </div>
        <div class="media-card-info card-footer border-0 bg-transparent pt-0">
          <span class="source d-block text-white font-weight-bold">{{ source }}</span>
          <span class="date small d-block text-white font-weight-bold">{{ date }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaCard',
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: 'oah-media-1'
    },
    date: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.media-card {
  padding: 5px;
}
</style>
