<template>
  <div class="position-relative bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-career-hero.svg`)})`}">
    <div class="container space-2 space-bottom-lg-3">
      <div class="w-md-85 w-lg-65 mx-auto text-center">
        <h1 class="">{{ $t('PressPage.HeroSection.Title') }}</h1>
        <p class="lead">{{ $t('PressPage.HeroSection.Description') }}</p>
      </div>
    </div>
    <figure class="position-absolute right-0 bottom-0 left-0 mb-n1">
      <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
      </svg>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
</style>
