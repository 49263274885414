<template>
  <div>
    <Hero />
    <Media />
  </div>
</template>

<script>
import Hero from './components/hero'
import Media from './components/media'

export default {
  name: 'Press',
  components: {
    Hero,
    Media
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Press | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/press' }
      ],
      meta: [
        { name: 'description', content: 'Discover the latest news coverage and updates about API Hub Community.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Press | OpenAPIHub' },
        { property: 'og:description', content: 'Discover the latest news coverage and updates about API Hub Community.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/press' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
