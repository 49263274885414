<template>
  <div>
    <div class="container space-2">
      <div class="row">
        <MediaCard
          v-for="(news, index) in mediaCoverageData"
          :id="news.id"
          :key="index"
          class="col-md-6 col-lg-4 mb-3"
          :title="$t(news.title)"
          :source="$t(news.source)"
          :background="news.background"
          :date="$t(news.date)"
          :link="$t(news.link)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MediaCard from '@/components/MediaCard'
import MediaCoverageData from '@/data/media-coverage.json'

export default {
  name: 'MediaCoverage',
  components: {
    MediaCard
  },
  computed: {
    mediaCoverageData() {
      return MediaCoverageData.sort((a, b) => (a.id > b.id) ? -1 : ((b.id > a.id) ? 1 : 0))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
